<template>
  <div id="light" ref="appRef">
    <div class="bg">
      <div class="light-body">

        <MyHeader :title="title"></MyHeader>

        <div class="body-box">
          <!-- 左侧数据 -->
          <div class="left-box">
            <div>
              <dv-border-box-12>
                <left :time="this.time"/>
              </dv-border-box-12>
            </div>
          </div>
          <!-- 中间数据 -->
          <div class="mid-box">
            <div>
              <dv-border-box-12>
                <mid :table="this.MachineAnomalyTable"/>
              </dv-border-box-12>
            </div>
          </div>
          <!-- 右侧数据 -->
          <div class="right-box">
            <div>
              <dv-border-box-12>
                <right :count="this.AnomalyCount"/>
              </dv-border-box-12>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>


<script>
import {defineComponent} from 'vue'
import drawMixin from "@/utils/drawMixin";
import MyHeader from "../../components/header/myHeader.vue";
import left from "@/views/light/left.vue";
import right from "@/views/light/right.vue";
import mid from "@/views/light/mid.vue";
import light from "@/api/light";
import {calculateTwoTimeDifference, extractMinutes} from "@/utils/date";


export default defineComponent({
  name: "light",
  mixins: [drawMixin],
  data() {
    return {
      title: "设备异常分析",
      time: [],
      number: [],
      AnomalyCount: [],
      MachineAnomalyTable: [],
    }
  },
  components: {left, right, MyHeader, mid},
  mounted() {
    // setInterval(() => {
    //   this.fetchData();
    // }, 3000);
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      light.getAnomalyAnalysis().then(response => {
        console.log(response)

        // 机器异常分布
        let newArray = [];
        for (let i = 0; i < 5; i++) {
          let sum = response.data.data.Count[i] + response.data.data.Count[i + 5];
          newArray.push(sum);
        }
        this.AnomalyCount = newArray;
        // console.log(this.AnomalyCount)

        // 机器异常表格
        let tableArray = [];
        response.data.data.Anomalys1.forEach((item) => {
          let obj = {};
          obj.machine_id = item.MachineID;
          obj.date = item.Time;
          obj.status = item.MachineStatusID;


          let IsWaitOrProcess = response.data.data.Anomalys2.some(obj => obj.MachineID === item.MachineID);
          if (IsWaitOrProcess) {
            let ProcessObject = response.data.data.Anomalys2.find(obj => obj.MachineID === item.MachineID);
            obj.wait_time = calculateTwoTimeDifference(item.Time, ProcessObject.Time);
            obj.process_time = calculateTwoTimeDifference(ProcessObject.Time, new Date());
            obj.type = "<span  class='colorBlue'>处理中</span>"
          } else {
            obj.wait_time = calculateTwoTimeDifference(item.Time, new Date());
            obj.process_time = '---';
            obj.type = "<span  class='colorGrass'>等待中</span>"
          }
          if (item.MachineStatusID >= 5 && item.MachineStatusID <= 14) {
            tableArray.push(obj);
          }
        })
        this.MachineAnomalyTable = tableArray;
        console.log(this.MachineAnomalyTable)


        // 时间
        let timeArray = [
          {index: '1', waitTime: 0, waitTimeSum: 0, processTime: 0, processTimeSum: 0},
          {index: '2', waitTime: 0, waitTimeSum: 0, processTime: 0, processTimeSum: 0},
          {index: '3', waitTime: 0, waitTimeSum: 0, processTime: 0, processTimeSum: 0},
          {index: '4', waitTime: 0, waitTimeSum: 0, processTime: 0, processTimeSum: 0},
          {index: '5', waitTime: 0, waitTimeSum: 0, processTime: 0, processTimeSum: 0},
        ];
        tableArray.forEach((item) => {
          console.log(item.status)
          if (item.status < 10 && item.status > 4) {
            // 字符串处理提取数据
            // console.log(item.status, '---item.status---')
            timeArray[item.status - 5].waitTimeSum += extractMinutes(item.wait_time);
          } else if (item.status < 15 && item.status > 9) {
            // console.log(item.status)
            timeArray[item.status - 10].waitTimeSum += extractMinutes(item.wait_time);
            timeArray[item.status - 10].processTimeSum += extractMinutes(item.process_time);
          }
        })
        timeArray.forEach((item) => {
          item.waitTime = (item.waitTimeSum / tableArray.length).toFixed(2);
          item.processTime = item.processTimeSum / tableArray.length.toFixed(2);
        })
        console.log(timeArray)
        this.time = timeArray;
      })
    },
  },
})
</script>

<style scoped lang="scss">
@import '../../assets/scss/light.scss';
</style>
