<template>
  <div>
    <Chart :cdata="cdata"/>
  </div>
</template>

<script>
import Chart from './chart.vue'

export default {
  data() {
    return {
      cdata: {
        data: [],
      },
      type: [
        '机械磨损',
        '电气故障',
        '程序出错',
        '控制故障',
        '操作错误',
      ]
    };
  },
  components: {
    Chart,
  },
  props: {
    count: {
      type: Array,
      required: true,
    },
  },
  watch: {
    count: {
      handler: function (newVal) {
        console.log(newVal)
        this.cdata.data = []
        newVal.forEach((value, index) => {
          if (value !== 0) {
            this.cdata.data.push({
              value: value,
              name: this.type[index]
            })
          }
        })
      },
      deep: true
    }
  },
  mounted() {
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
</style>
