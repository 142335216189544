import http from '../utils/request.js'


const light = {
    getAnomalyAnalysis(id) {
        if (!id) id = 1
        const params = {
            workshop_id: id,
        }
        return http.post(`/screen/anomaly`, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        })
    },

}

export default light;
